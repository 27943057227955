import React, { useState, useEffect, memo } from "react";
import Modal from "react-bootstrap/Modal";

const ContactModal = ({ onHide }) => {
  const [resultMessage, setResultMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [countdown, setCountdown] = useState(8);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    const regex = /^\+?[1-9]\d{1,14}$/; // International phone number format
    return regex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const object = Object.fromEntries(formData);
    const { name, email, phone } = object;

    let formErrors = {};

    if (!name) {
      formErrors.name = "Name Required";
    }

    if (!validateEmail(email)) {
      formErrors.email = "Invalid email address";
    }

    if (!validatePhone(phone)) {
      formErrors.phone = "Invalid phone number";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const json = JSON.stringify(object);
    setResultMessage("Please wait...");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });
      const result = await response.json();
      if (response.status === 200) {
        setResultMessage(result.message);
        setIsSubmitted(true);
      } else {
        console.log(response);
        setResultMessage(result.message);
      }
    } catch (error) {
      console.log(error);
      setResultMessage("Something went wrong!");
    }

    form.reset();
  };

  useEffect(() => {
    if (!isSubmitted) return; // Skip countdown if not submitted

    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(interval);
          setResultMessage("");
          onHide();
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isSubmitted, countdown, onHide]);

  return (
    <>
      <Modal show={true} onHide={onHide} scrollable>
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body>
          <div className="p-4 pt-0">
            {resultMessage ? (
              <div
                id="result"
                className="mt-3 alert alert-success fs-5 text-center"
              >
                {resultMessage}
                {isSubmitted && (
                  <div className="mt-3">Redirecting in {countdown}s</div>
                )}
              </div>
            ) : (
              <form onSubmit={handleSubmit} id="form">
                <fieldset>
                  <input
                    type="hidden"
                    name="access_key"
                    value="794d41c6-dc99-4ab3-99d8-738247d44052"
                  />
                  <input
                    type="hidden"
                    name="subject"
                    value="Inquiry Received – Let's Get Started"
                  />
                  <input
                    type="hidden"
                    name="redirect"
                    value="https://web3forms.com/success"
                  />
                  <input
                    type="checkbox"
                    name="botcheck"
                    id=""
                    style={{ display: "none" }}
                  />
                  <input type="hidden" name="from_name" value="Auxo Capital" />
                  <div className="mb-3">
                    <label
                      htmlFor="name"
                      className="form-label small text-secondary fw-bold"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                    />
                    {errors.name && (
                      <div className="text-danger small">{errors.name}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className="form-label small text-secondary fw-bold"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="form-control error"
                    />
                    {errors.email && (
                      <div className="text-danger small">{errors.email}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="phone"
                      className="form-label small text-secondary fw-bold"
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      className="form-control"
                    />
                    {errors.phone && (
                      <div className="text-danger small">{errors.phone}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="message"
                      className="form-label small text-secondary fw-bold"
                    >
                      Your Message
                    </label>
                    <textarea
                      rows="3"
                      name="message"
                      id="message"
                      className="form-control"
                      placeholder="type your message here..."
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="btn text-white fs-5 theme-bg-color border-0 w-100 mt-2"
                  >
                    Send Message
                  </button>
                </fieldset>
              </form>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(ContactModal);
