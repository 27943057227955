import "./App.css";
import Footer from "./components/Footer";
import TopNavbar from "./components/TopNavbar";
import PublicRoutes from "./routes/PublicRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { CategoryScale } from "chart.js";
import usePreloadImages from "./hooks/usePreloadImages";
import img1 from "./assets/1.jpg";
import img2 from "./assets/2.jpg";
import img3 from "./assets/founder.jpg";
import img4 from "./assets/hero.jpg";

// Register the chart scale only if you use chart.js functionality
import { Chart } from "chart.js";
Chart.register(CategoryScale);

function App() {
  const allImages = [img1, img2, img3, img4];

  usePreloadImages(allImages);

  return (
    <>
      <header className="sticky-top">
        <TopNavbar />
      </header>
      <main>
        <PublicRoutes />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
