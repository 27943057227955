import React, { useState, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { MonthlyReturns } from "../data/returns";

const QuarterlyReturn = () => {
  const [selectedQuarter, setSelectedQuarter] = useState(0);

  const data = useMemo(
    () => ({
      labels: MonthlyReturns[selectedQuarter].labels,
      datasets: [
        {
          label: "Nifty 500",
          data: MonthlyReturns[selectedQuarter].nifty,
          backgroundColor: "rgba(95, 83, 160, 0.5)",
          borderColor: "rgba(95, 83, 160, 1)",
          borderWidth: 2,
          borderRadius: 5,
        },
        {
          label: "Auxo Capital",
          data: MonthlyReturns[selectedQuarter].auxo,
          backgroundColor: "rgba(194, 96, 162, 0.5)",
          borderColor: "rgba(194, 96, 162, 1)",
          borderWidth: 2,
          borderRadius: 5,
        },
      ],
    }),
    [selectedQuarter]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "top",
          labels: {
            color: "rgba(0, 0, 0, 0.7)",
            font: {
              size: 14,
            },
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return `${context.dataset.label}: ${context.parsed.y}%`;
            },
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Months",
            // color: "rgba(0, 0, 0, 0.7)",
            // font: {
            //   size: 24,
            // },
          },
          ticks: {
            color: "rgba(0, 0, 0, 0.7)",
          },
        },
        y: {
          title: {
            display: true,
            text: "Percentage",
            // color: "rgba(0, 0, 0, 0.7)",
            // font: {
            //   size: 24,
            // },
          },
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return value + "%";
            },
            // color: "rgba(0, 0, 0, 0.7)",
          },
        },
      },
    }),
    []
  );

  return (
    <div className="my-5 col-12 col-lg-10 offset-lg-1 theme-bg-secondary p-0 p-lg-5 rounded shadow">
      <div className="d-flex flex-column">
        <p className="display-5 fw-bold mb-4 text-center text-lg-start p-4 pb-0 p-lg-0">
          Quarterly returns
        </p>
        <div className="dropdown align-self-lg-end align-self-center mb-4 btn-sm">
          <button
            className="btn border border-1 fw-bold border-dark dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {MonthlyReturns[selectedQuarter].text}
          </button>
          <ul className="dropdown-menu">
            {MonthlyReturns.map((option, index) => (
              <li key={option.text}>
                <button
                  className="dropdown-item btn"
                  onClick={() => setSelectedQuarter(index)}
                >
                  {option.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="p-2 p-lg-0">
          <Bar data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(QuarterlyReturn);
