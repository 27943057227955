import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { YearlyReturns } from "../data/returns";

const YearlyReturn = React.memo(() => {
  //   const [selectedQuarter, setSelectedQuarter] = useState(0);

  const data = {
    labels: YearlyReturns[0].labels,
    datasets: [
      {
        label: "Nifty 500",
        data: YearlyReturns[0].nifty,
        backgroundColor: "rgba(95, 83, 160, 0.5)",
        borderColor: "rgba(95, 83, 160, 1)",
        borderWidth: 2,
        fill: false,
        tension: 0,
      },
      {
        label: "Auxo Capital",
        data: YearlyReturns[0].auxo,
        backgroundColor: "rgba(194, 96, 162, 0.5)",
        borderColor: "rgba(194, 96, 162, 1)",
        borderWidth: 2,
        fill: false,
        tension: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          color: "rgba(0, 0, 0, 0.7)",
          font: {
            // size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.parsed.y}%`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months",
          //   color: "rgba(0, 0, 0, 0.7)",
          //   font: {
          //     size: 16,
          //   },
        },
        ticks: {
          color: "rgba(0, 0, 0, 0.7)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Percentage",
          //   color: "rgba(0, 0, 0, 0.7)",
          //   font: {
          //     size: 16,
          //   },
        },
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return value + "%";
          },
          color: "rgba(0, 0, 0, 0.7)",
        },
        min: -5,
        max: 20,
      },
    },
  };

  return (
    <div className="my-5 col-12 col-lg-10 offset-lg-1 theme-bg-secondary rounded shadow p-0 p-lg-5">
      <div className="d-flex flex-column">
        <p className="display-5 fw-bold mb-4 text-center text-lg-start p-4 pb-0 p-lg-0">
          Return (Year To Date)
        </p>

        {/* dropdown */}

        {/* <div className="dropdown align-self-end mb-4">
        <button
          className="btn border border-2 border-secondary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {YearlyReturns[selectedQuarter].text}
        </button>
        <ul className="dropdown-menu">
          {YearlyReturns.map((option, index) => (
            <li key={index}>
              <button
                className="dropdown-item btn"
                onClick={() => setSelectedQuarter(index)}
              >
                {option.text}
              </button>
            </li>
          ))}
        </ul>
      </div> */}
        <div className="p-2 p-lg-0">
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
});

export default YearlyReturn;
