import React, { memo } from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <div className="py-5 theme-bg-secondary">
      <Container className="d-flex justify-content-center small">
        {new Date().getFullYear()} © | All rights reserved
      </Container>
    </div>
  );
};

export default memo(Footer);
