import { Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "../components/LandingPage";
import Returns from "../components/Returns";
import About from "../components/About";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/returns" element={<Returns />} />
      <Route path="/about" element={<About />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default PublicRoutes;
