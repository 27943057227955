import React, { useEffect } from "react";
import QuarterlyReturn from "./QuarterlyReturn";
import YearlyReturn from "./YearlyReturn";

const Returns = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex justify-content-center theme-bg-color">
      <div className="col-10 col-lg-8">
        <YearlyReturn />
        <QuarterlyReturn />
      </div>
    </div>
  );
};

export default React.memo(Returns);
