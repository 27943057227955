import React, { useEffect, useRef, useState, useCallback, memo } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import ContactModal from "./ContactModal";

const DesktopNavbar = memo(({ handleContactUs, scrollToSection }) => {
  return (
    <Navbar className="">
      <Container>
        <Navbar.Brand href="#" className="fs-2 fw-bold me-5">
          <Link
            className="me-4 nodecor text-light fw-bold"
            to="/"
            onClick={() => scrollToSection("home")}
          >
            Auxo Capital
          </Link>
        </Navbar.Brand>
        <Nav className="d-flex align-items-center">
          <Link
            className="btn nodecor link-light fw-bold"
            to="/"
            onClick={() => scrollToSection("philosophy")}
          >
            Philosophy
          </Link>
          <Link className="btn nodecor link-light fw-bold" to="/returns">
            Returns
          </Link>
          <Link className="btn nodecor link-light fw-bold" to="/about">
            About
          </Link>
          <Button
            variant="light"
            className="fw-bold rounded mx-3 text-truncate"
            onClick={handleContactUs}
          >
            Contact Us
          </Button>
        </Nav>
      </Container>
    </Navbar>
  );
});

const MobileNavbar = memo(({ handleContactUs, scrollToSection }) => {
  const navbarRef = useRef(null);
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = () => setExpanded(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Navbar
      ref={navbarRef}
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      expand="xl"
    >
      <Container className="theme-bg-color">
        <Navbar.Brand href="#" className="fs-2 fw-bold">
          <Link
            className="me-4 nodecor text-light fw-bold"
            to="/"
            onClick={() => {
              handleLinkClick();
              scrollToSection("home");
            }}
          >
            Auxo Capital
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="navbar navbar-dark shadow-none"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <hr className="text-white"></hr>
          <Nav className="flex-column align-items-center">
            <Link
              className="mb-3 nodecor link-light fw-bold small"
              to="/"
              onClick={() => {
                handleLinkClick();
                scrollToSection("philosophy");
              }}
            >
              Philosophy
            </Link>
            <Link
              className="mb-3 nodecor link-light fw-bold small"
              to="/returns"
              onClick={handleLinkClick}
            >
              Returns
            </Link>
            <Link
              className="mb-3 nodecor link-light fw-bold small"
              to="/about"
              onClick={handleLinkClick}
            >
              About
            </Link>
          </Nav>
          <Button
            variant="light"
            className="fw-bold w-100 mb-4"
            size="sm"
            onClick={handleContactUs}
          >
            Contact Us
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
});

const TopNavbar = memo(() => {
  const [showModal, setShowModal] = useState(false);

  const scrollToSection = useCallback((section) => {
    switch (section) {
      case "home":
        window.scrollTo(0, 0);
        break;
      case "philosophy":
        setTimeout(() => {
          document
            .getElementById(section)
            .scrollIntoView({ behavior: "smooth" });
        }, 100);
        break;
      default:
        window.scrollTo(0, 0);
        break;
    }
  }, []);

  return (
    <div className="py-2 navbar-top theme-bg-color">
      {showModal && <ContactModal onHide={() => setShowModal(false)} />}

      <div className="col-12 col-lg-10 offset-lg-1">
        <div className="d-none d-lg-block">
          <DesktopNavbar
            handleContactUs={() => setShowModal(true)}
            scrollToSection={scrollToSection}
          />
        </div>
        <div className="d-block d-lg-none">
          <MobileNavbar
            handleContactUs={() => setShowModal(true)}
            scrollToSection={scrollToSection}
          />
        </div>
      </div>
    </div>
  );
});

export default TopNavbar;
